import 'matchmedia-polyfill';
import '../styles/index.scss';
import lozad from 'lozad';
import mediumZoom from 'medium-zoom';
import throttle from 'lodash.throttle';
import MicroModal from 'micromodal';

const mq_sizes = {
  tablet: '700px',
  tablet769: '769px',
  desktop: '850px',
  widescreen: '1192px',
  fullhd: '1384px',
  xxlscreen: '2100px'
};

const images_load_first = [].slice.call(document.querySelectorAll('.palm-loading'));

// Lazy loading
const loadFade = function (el) {
  if(!el.getAttribute('data-responsiv') || ( el.getAttribute('data-responsiv') && mq_sizes[el.getAttribute('data-responsiv')] && matchMedia('only screen and (min-width: ' + mq_sizes[el.getAttribute('data-responsiv')] + ')').matches)) {
    el.classList.add('animated');
    el.classList.add('fadeIn');
    if (el.getAttribute('data-src')) {
      el.src = el.getAttribute('data-src');
    }
    if (el.getAttribute('data-srcset')) {
      el.srcset = el.getAttribute('data-srcset');
    }
    if (el.getAttribute('data-background-image')) {
      el.style.backgroundImage = `url(${el.getAttribute('data-background-image')})`;
    }
  }
};

const observer = lozad('.lozad', {
  load: loadFade
});

images_load_first.forEach((image) => {
  observer.triggerLoad(image);
});

observer.observe();

mediumZoom('.zoomable');

MicroModal.init();

// Menu
let burger = document.getElementById('burger');
let menu = document.getElementById('menu');
burger.addEventListener('click', function(e) {
  if(burger.classList.contains('is-open')) {
     burger.classList.remove('is-open');
     burger.classList.remove('is-active');
     menu.classList.remove('is-open');
  } else {
    burger.classList.add('is-open');
    burger.classList.add('is-active');
    menu.classList.add('is-open');
  }
}, false);

// End loading
window.addEventListener("load", function(event) {
  document.getElementById('loader').classList.add('banner--loader-isloaded');
});
